import flattenDeep from 'lodash/flattenDeep';
import isMatch from 'lodash/isMatch';
import arraysIncludeOrOverlap from 'tembo-js/arraysIncludeOrOverlap';
import getDataByProperty from 'tembo-js/getDataByProperty';
import flattenByProperty from 'tembo-js/flattenByProperty';
import sendEvent from 'tembo-js/sendEvent';
import getQueryString from 'tembo-js/getQueryString';

module.exports = {
  data: function data() {
    const dataObj = {};
    const columns = this.settings.columns;
    if (columns) {
      dataObj.dependentColumns = columns.filter(col => col.conditional_column);
    }
    return dataObj;
  },
  computed: {
    breakpoint: function breakpoint() {
      return this.$store.state.breakpoint;
    },
    filters: function filters() {
      return this.$store.getters.filterSet;
    },
    columnDependencies: function columnDependencies() {
      if (!this.settings.columns || !this.settings.columns.length) {
        return {};
      }
      const dependentColumns = this.dependentColumns;
      const dependentColumnsHash = {};
      for (let i = 0, l = dependentColumns.length; i < l; i ++) {
        const col = dependentColumns[i];
        const condition = col.conditional_column;
        let dependency = getDataByProperty(condition.source, this);
        if (Array.isArray(dependency)) {
          dependency = dependency.filter(d => d[condition.filter_by.field] === condition.filter_by.value); // eslint-disable-line max-len
          dependency = flattenByProperty(condition.flatten_by, dependency);
          dependency = flattenDeep(dependency);
        }
        dependentColumnsHash[col.id] = dependency;
      }
      return dependentColumnsHash;
    },
    filteredColumns: function filteredColumns() {
      let filtered;
      const sort = this.$store.state.sort;
      const columns = this.settings.columns;
      if (!columns || !columns.length) filtered = [];
      else {
        const columnDependencies = this.columnDependencies;
        filtered = columns.filter(col => {
          if (col.conditional_column) {
            const condition = col.conditional_column;
            return arraysIncludeOrOverlap(columnDependencies[col.id], condition.allowed_value); // eslint-disable-line max-len
          }
          return true;
        }).map(col => {
          if (sort) {
            const appSort = sort;
            const newCol = col;
            if (col.entity_prop === appSort.field) {
              newCol.sort_direction = appSort.direction;
            }
            return newCol;
          }
          return col;
        }, this);
      }
      return filtered;
    },
    filteredColumnIds: function filteredColumnIds() {
      return this.filteredColumns.map(col => col.id);
    },
  },
  methods: {
    setInitialTableSort(reset) {
      // sort already saved to app state
      const appSort = this.$store.state.sort;
      let getSortFromColumns;
      const filteredColumns = this.filteredColumns;
      const sortOnLoad = this.settings.hasOwnProperty('sort_on_load') ? this.settings.sort_on_load : true; // eslint-disable-line max-len

      // if there are no columns allowed in the table currently, do nothing
      if (!this.filteredColumns || !this.filteredColumns.length) return;


      if (reset || (!appSort && sortOnLoad)) {
        getSortFromColumns = true;
      } else if (!sortOnLoad) {
        getSortFromColumns = false;
      } else {
        // find the column(s) that match the current app sort
        getSortFromColumns = this.filteredColumns
                          .filter(col => {
                            if (col.sort_prop === appSort.field || col.entity_prop === appSort.field) { // eslint-disable-line max-len
                              return true;
                            }
                            return false;
                          });
        // if there are any matches, do NOT update the app sort
        getSortFromColumns = !(getSortFromColumns.length > 0);
      }
      // if there is no app sort,
      // or the reset flag was passed,
      // or there are no matches
      if (getSortFromColumns) {
        let initialSortColumn = filteredColumns.filter(col => col.is_initial_table_sort);
        const autoSort = filteredColumns.filter(col => col.conditional_column && col.conditional_column.auto_sort); // eslint-disable-line max-len

        if (autoSort.length === 1) {
          initialSortColumn = autoSort[0];
        } else if (initialSortColumn.length !== 1) {
          console.warn('To specify which column will sort the table initially, set the "is_initial_table_sort" property to true on the relevant column. Otherwise, the table will sort on the first column initially.'); // eslint-disable-line max-len, no-console
          initialSortColumn = filteredColumns[0];
        } else {
          initialSortColumn = initialSortColumn[0];
        }
        initialSortColumn.sort_direction = initialSortColumn.sort_direction_initial || 1;
        this.updateSort(initialSortColumn);
        this.clearOtherSorts(initialSortColumn);
      }
    },
    makeSortFromColumn: function makeSortFromColumn(col, direction, headerProp = 'col_header') {
      let sortDirection = direction;
      if (!sortDirection) sortDirection = col.sort_direction;
      if (!sortDirection) sortDirection = col.direction;

      let textSuffix = '';
      if (sortDirection === 1 && col.asc_suffix) {
        textSuffix = col.asc_suffix;
      } else if (sortDirection === -1 && col.desc_suffix) {
        textSuffix = col.desc_suffix;
      }

      const headerText = getDataByProperty(headerProp, col);

      const sort = {
        value: `${col.id}_${sortDirection === 1 ? 'asc' : 'desc'}`,
        field: col.sort_prop || col.entity_prop,
        direction: sortDirection,
        text: headerText + textSuffix,
      };

      if (col.custom_sort_order) {
        sort.custom_sort_order = col.custom_sort_order;
      }
      if (col.correlation) { // for related sorts
        sort.correlation = col.correlation;
      }
      if (col.related_sort) {
        const relatedSort = col.related_sort;
        sort.related_sort = this.makeSortFromColumn(relatedSort);
      }
      // allow sort_prop to override the property/properties that the column displays
      if (col.sort_prop) {
        sort.field = col.sort_prop;
      }
      return sort;
    },
    toggleSort: function toggleSort(idx) {
      //
      // updates the app's sort field & direction
      // removes all other sorts
      //
      const col = this.filteredColumns[idx];
      const directionInitial = col.sort_direction_initial || 1;
      const directionOpposite = -1 * directionInitial;
      const directionCurrent = col.sort_direction;

      // prevent sorting on columns where we shouldn't
      if (!col.is_sortable) return;

      if (!directionCurrent || directionCurrent === 0 || directionCurrent === directionOpposite) {
        col.sort_direction = directionInitial;
      } else if (col.sort_direction === directionInitial) {
        col.sort_direction = directionOpposite;
      }

      this.filteredColumns[idx] = col;
      this.updateSort(col);
      this.clearOtherSorts(col);
    },
    updateSort: function updateSort(col) {
      //
      // sends google analytics event
      // dispatches event to store
      //
      const sortValue = this.makeSortFromColumn(col);
      if (this.$store.state.sort && isMatch(sortValue, this.$store.state.sort)) {
        // sort isn't actually different. don't bother updating everything
        return;
      }

      const findView = this.$route.params.findView;
      const category = findView ? `${findView}_list` : 'list ';
      sendEvent({
        category: category,
        action: 'table_sort',
        label: sortValue.value
      });
      this.$store.dispatch('updateSort', sortValue);
    },
    getEntityLink: function getEntityLink(link) {
      if (!link) {
        return null;
      }
      const entity = this.entity;
      let href = '';
      if (link.base_url) {
        href += link.base_url;
      }
      if (link.entity_prop) {
        if (typeof link.entity_prop === 'object' && !Array.isArray(link.entity_prop)) {
          const keys = Object.keys(link.entity_prop);
          for (let i = 0, l = keys.length; i < l; i ++) {
            const linkKey = keys[i];
            const entityKey = link.entity_prop[linkKey];
            const entityLinkData = getDataByProperty(entityKey, entity);
            if (entityLinkData || entityLinkData === 0) {
              href = href.replace(`[${linkKey}]`, entityLinkData);
            }
          }
        } else if (typeof link.entity_prop === 'string') {
          const entityLinkData = getDataByProperty(link.entity_prop, entity);
          if (entityLinkData || entityLinkData === 0) {
            href = href.replace('[entity_id]', entityLinkData);
          }
        } else {
          console.error('link.entity_prop must be a single entity property as a string or a set of key-value pairs'); // eslint-disable-line no-console, max-len
          href = '';
        }
      }

      return href + getQueryString(this.$route.query);
    },
    clearOtherSorts: function clearOtherSorts(col) {
      const columns = this.settings.columns;
      for (let i = 0, l = columns.length; i < l; i ++) {
        if (columns[i].id !== col.id) {
          columns[i].sort_direction = 0;
        }
      }
    }
  }
};
