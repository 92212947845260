// because of the need to break out of mapped lists and pull in data from
// globals or elsewhere (using type: 'root' in the mapping yamls, which
// make use of the mapRoot function), this module relies heavily on closures
// at some point, it's worth revisiting to see if there is a better way to handle this

import passesFilters from './passesFilters'; // eslint-disable-line import/no-cycle
import * as timesSA from './timesSanAntonio';
import toNumber from './convertToNumberIfPossible';

/* eslint-disable no-use-before-define */
function handleDataMap(dataMap, settings) {
  function parseApi(source, key) {
    let keys;
    let result;
    if (!key) {
      return null;
    }
    if (!source) {
      return null;
    }
    if (key === 'self') return source;
    if (typeof key === 'string') keys = key.split('.');
    else keys = key;
    let k;
    try {
      k = keys.shift();
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
    if (Object.prototype.hasOwnProperty.call(source, k)) {
      result = source[k];
    } else if (Array.isArray(source)) {
      result = source.find(item => item.id === k);
      if (!result) return null;
    } else {
      return null;
    }
    if (keys.length === 0) {
      return result;
    }
    return parseApi(result, keys);
  }
  // function mergeList(listSource, dataMap) { // eslint-disable-line no-shadow
  //   const result = [];
  //   let listLength;
  //   let key;
  //   let each;
  //   if (!listSource) return result;
  //   const source = mapData(dataMap.value, listSource);
  //   if (!source) return result;
  //   const keys = Object.keys(source);
  //   for (let i = 0, len = keys.length; i < len; i++) {
  //     key = keys[i];
  //     if (!listLength && Array.isArray(source[key])) {
  //       try {
  //         listLength = source[key].length;
  //       } catch (e) {
  //         // eslint-disable-next-line
  //         console.error(e);
  //         return [];
  //       }
  //     } else if (Array.isArray(source[key]) && source[key].length !== listLength) {
  //       // console.error('lists are not equal length');
  //       return [];
  //     }
  //   }
  //   if (!listLength) result.push(source);
  //   else {
  //     for (let i = 0; i < listLength; i++) {
  //       each = {};
  //       for (let j = 0, len = keys.length; j < len; j++) {
  //         key = keys[j];
  //         each[key] = source[key][i];
  //       }
  //       result.push(each);
  //     }
  //   }
  //   return result;
  // }


  function objectToArray(source, dataMap) { // eslint-disable-line no-shadow
    if (!source) return null;
    if (typeof source !== 'object') return null;
    if (Array.isArray(source)) return source;
    const keys = Object.keys(source);
    let result = keys.map(k => ({ key: k, value: source[k] }));
    // allow filtering & re-mapping
    if (dataMap.filter) {
      result = reduceList(result, dataMap);
    } else if (dataMap.value) {
      result = mapList(result, dataMap);
    }
    return result;
  }

  function flattenList(source, dataMap) { // eslint-disable-line no-shadow
    if (Array.isArray(source)) return source.map(s => parseApi(s, dataMap.flatten_key));
    return null;
  }

  function mapList(source, extractInfo) {
    const result = [];
    if (!source) return null;
    if (Array.isArray(source)) {
      for (let i = 0, l = source.length; i < l; i++) {
        const item = source[i];
        const value = mapData(item, extractInfo.value);
        result.push(value);
      }
    } else {
      const item = source;
      const value = mapData(item, extractInfo.value);
      if (value) result.push(value);
    }
    return result;
  }

  function filterList(source, extractInfo) {
    if (!source) return null; // COMPARE returns []
    let sourceList;
    if (!Array.isArray(source)) {
      sourceList = [source];
    } else {
      sourceList = source;
    }

    // accepts both an object with a single filter,
    // and an array of filters
    let filters = [];
    if (Array.isArray(extractInfo.filter)) {
      filters = extractInfo.filter;
    } else if ('filter' in extractInfo && extractInfo.filter !== undefined) {
      filters.push(extractInfo.filter);
    } else {
      return source;
    }
    filters = filters.map((filter) => {
      const newFilter = JSON.parse(JSON.stringify(filter));
      if (newFilter.dynamic) {
        newFilter.value = mapData(source, newFilter.value);
        return newFilter;
      }
      return newFilter;
    });
    const item = sourceList.find(i => passesFilters(i, filters, settings));
    if (!item) return null;
    return mapData(item, extractInfo.value);
  }

  /*  eslint-disable no-else-return */
  function mapConditional(source, dataMap) { // eslint-disable-line no-shadow
    const condition = mapData(source, dataMap.condition);
    if (condition.operation === 'equals') {
      if (condition.left === condition.right) {
        return mapData(source, dataMap.value);
      }
      return mapData(source, dataMap.else);
    } else if (condition.operation === 'not_empty') {
      if (condition.left !== null && condition.left !== undefined) {
        return mapData(source, dataMap.value);
      }
      return mapData(source, dataMap.else);
    }
    console.error('conditional does not yet support', condition.operation); // eslint-disable-line no-console, max-len
    return null;
  }
  /*  eslint-enable no-else-return */

  function reduceList(sourceList, extractInfo) {
    let filters = [];
    let reducedList;
    const source = JSON.parse(JSON.stringify(sourceList));

    if (!sourceList) return null; // COMPARE returns an empty list

    // Accept both an object with a single filter,
    // and an array of filters
    if (Array.isArray(extractInfo.filter)) {
      filters = extractInfo.filter;
    } else if ('filter' in extractInfo && extractInfo.filter !== undefined) {
      filters.push(extractInfo.filter);
    } else {
      return sourceList;
    }

    if (Array.isArray(source)) {
      reducedList = source.filter(i => passesFilters(i, filters, settings));
    } else if (passesFilters(source, filters, settings)) {
      reducedList = [source];
    }

    if (reducedList && extractInfo.value) {
      reducedList = reducedList.map(i => mapData(i, extractInfo.value));
      return reducedList;
    }
    if (reducedList) {
      return reducedList;
    }

    return [];
  }

  function joinStrings(source, dataMap) { // eslint-disable-line no-shadow
    let result = [];
    if (Array.isArray(dataMap.source)) {
      for (let i = 0, l = dataMap.source.length; i < l; i++) {
        const map = dataMap.source[i];
        result.push(mapData(source, map));
      }
    } else {
      result.push(mapData(source, dataMap.source));
    }
    const joinWith = Object.prototype.hasOwnProperty.call(dataMap, 'join_with') ? dataMap.join_with : ','; // eslint-disable-line max-len
    // remove items that are null/missing
    result = result.filter(item => item);
    if (result.length) return result.join(joinWith);
    return null;
  }

  function splitString(source, dataMap) { // eslint-disable-line no-shadow
    const result = mapData(source, dataMap.source);
    if (typeof result !== 'string') return result;
    const splitOn = dataMap.split_on || ',';
    return result.split(splitOn);
  }

  function mapSwitch(source, dataMap) { // eslint-disable-line no-shadow
    const expression = mapData(source, dataMap.expression);
    const cases = mapData(source, dataMap.cases);
    for (let i = 0, l = cases.length; i < l; i++) {
      if (expression.operation.toLowerCase() === 'equals') {
        if (expression.left === cases[i].case) {
          return cases[i].value;
        }
      } else if (expression.operation.toLowerCase() === 'gte') {
        const left = toNumber(expression.left, null);
        const right = toNumber(cases[i].case, null);
        if (left > right || left === right) {
          return cases[i].value;
        }
      } else if (expression.operation.toLowerCase() === 'lte') {
        const left = toNumber(expression.left, null);
        const right = toNumber(cases[i].case, null);
        if (left < right || left === right) {
          return cases[i].value;
        }
      } else if (expression.operation.toLowerCase() === 'gt') {
        const left = toNumber(expression.left, null);
        const right = toNumber(cases[i].case, null);
        if (left > right) {
          return cases[i].value;
        }
      } else if (expression.operation.toLowerCase() === 'lt') {
        const left = toNumber(expression.left, null);
        const right = toNumber(cases[i].case, null);
        if (left < right) {
          return cases[i].value;
        }
      } else {
        console.error('switch does not yet support', expression.operation); // eslint-disable-line no-console, max-len
        return null;
      }
    }
    if (dataMap.default) {
      const def = mapData(source, dataMap.default);
      return def;
    }
    return null;
  }

  function mapRoot(source, dataMap) { // eslint-disable-line no-shadow
    const result = mapData(settings, dataMap.value);
    return result;
  }

  function deepReplace(source, dataMap) { // eslint-disable-line no-shadow
    let sourceString;
    if (typeof dataMap.original === 'string') {
      sourceString = dataMap.original;
    } else {
      sourceString = JSON.stringify(dataMap.original);
    }
    for (let i = 0, l = dataMap.replacements.length; i < l; i++) {
      const replacement = dataMap.replacements[i];
      const replaceThis = mapData(source, replacement.find);
      const replaceWith = mapData(source, replacement.replace);
      sourceString = sourceString.replace(replaceThis, replaceWith);
    }
    return JSON.parse(sourceString);
  }

  function objectToKeys(data) {
    if (data && typeof (data) === 'object') {
      return Object.keys(data);
    }
    return null;
  }

  function mapData(source, dataMap) { // eslint-disable-line no-shadow
    let result = {};
    let keys;

    if (typeof dataMap === 'string') {
      result = parseApi(source, dataMap);
    } else if (Array.isArray(dataMap)) {
      //  handle arrays
      result = [];
      for (let i = 0, len = dataMap.length; i < len; i++) {
        result.push(mapData(source, dataMap[i]));
      }
    } else if (typeof dataMap === 'object') {
      try {
        keys = Object.keys(dataMap);
      } catch (e) {
        // eslint-disable-next-line
        console.error('error:', e, dataMap);
      }
      if (keys.indexOf('type') > -1) {
        if (dataMap.type === 'static') {
          // copy value to result
          result = JSON.parse(JSON.stringify(dataMap.value));
        } else if (dataMap.type === 'mapped_list') {
          result = mapList(mapData(source, dataMap.source), dataMap);
        } else if (dataMap.type === 'filtered_list') {
          const sourceList = mapData(source, dataMap.source);
          result = filterList(sourceList, dataMap);
        // } else if (dataMap.type === 'merged_list') {
        //   result = mergeList(parseApi(source, dataMap.source), dataMap);
        } else if (dataMap.type === 'flattened_list') {
          result = flattenList(mapData(source, dataMap.source), dataMap);
        } else if (dataMap.type === 'optional') {
          result = parseApi(source, dataMap.value);
        } else if (dataMap.type === 'conditional') {
          result = mapConditional(source, dataMap);
        } else if (dataMap.type === 'root') {
          result = mapRoot(source, dataMap);
        } else if (dataMap.type === 'switch') {
          result = mapSwitch(source, dataMap);
        } else if (dataMap.type === 'reduced_list') {
          result = reduceList(mapData(source, dataMap.source), dataMap);
        } else if (dataMap.type === 'join_strings') {
          result = joinStrings(source, dataMap);
        } else if (dataMap.type === 'split_string') {
          result = splitString(source, dataMap);
        } else if (dataMap.type === 'deep_replace') {
          result = mapData(source, deepReplace(source, dataMap));
        } else if (dataMap.type === 'sa_get_days') {
          result = timesSA.getDays(parseApi(source, dataMap.source));
        } else if (dataMap.type === 'sa_get_times') {
          result = timesSA.getTimes(parseApi(source, dataMap.source));
        } else if (dataMap.type === 'sa_get_seasons') {
          result = timesSA.getSeasons(parseApi(source, dataMap.source));
        } else if (dataMap.type === 'object_to_array') {
          result = objectToArray(mapData(source, dataMap.source), dataMap);
        } else if (dataMap.type === 'object_to_keys') { // flatten an object to just it keys
          result = objectToKeys(mapData(source, dataMap.source));
        } else {
          // eslint-disable-next-line no-console
          console.error(`tembo-js does not currently support type ${dataMap.type}`);
        }
      } else {
        for (let i = 0, len = keys.length; i < len; i++) {
          const key = keys[i];
          let res;
          if (dataMap[key] === null || dataMap[key] === '' || dataMap[key] === undefined) {
            console.error(key, 'in datamap', dataMap, 'is empty. Please fix'); // eslint-disable-line no-console, max-len
            res = null;
          } else {
            res = mapData(source, dataMap[key]);
          }
          if (dataMap[key].type === 'optional') {
            if (res !== null) result[key] = res;
          } else {
            result[key] = res;
          }
        }
      }
    }
    return result;
  }

  return mapData(settings, dataMap);
}
/* eslint-enable no-use-before-define */

export default handleDataMap;
