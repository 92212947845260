<template>
  <div style="box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2); background-color: #fff;">
  <nav class="container">
    <div class="row">
      <div class="col-12">
        <div class="p-nav">

          <!-- logo -->
          <a :href="settings.image_url + getQueryString($route.query)"><img :src="settings.image_src" :alt="settings.image_alt" class="p-nav__logo"></a>
          <div class="d-none  d-md-flex align-items-center align-self-end ml-80">
            <ul class="p-nav__list--primary sp-h-5">
              <li class="p-nav__item" v-for="link in settings.links" :class="$route.path === link.url ? 'p-nav__item--active' : ''"><a :href="link.url + getQueryString($route.query)" target="_blank">{{link.display_text}}</a>
               </li>             
            </ul>
          </div>

          <!-- desktop nav container -->
          <div class="d-none ml-auto d-md-flex align-items-center p-nav__lists flex-grow-0 ">
            <!-- primary nav links -->


            <!-- entity search -->
            <component
              v-if="layout && layout.entity_search && layout.entity_search.data"
              :is="layout.entity_search.tag || 'typeahead-dropdown-link'"
              :placeholder="layout.entity_search.data.placeholder"
              :options="layout.entity_search.data.options"
              :search-config="layout.entity_search.data.search_config"
              :display-config="layout.entity_search.data.display_config"
              :no-results="{text: ''}"
              :link-template="layout.entity_search.data.link_template"
            ></component>


            <ul class="p-nav__list--secondary">
              <li>
                <a :href="settings.assessment_link.href" style="text-transform: uppercase;" target="_blank">{{settings.assessment_link.text}}</a>
              </li>
            </ul>
            <!-- separator -->
            <div class="p-nav__seperator" v-if="showLanguages && settings.assessment_link.href">
            </div>

            <!-- secondary nav items -->
            <ul class="p-nav__list--secondary">
              <!-- language(s) -->
              <li v-if="showLanguages" v-for="lang in otherLanguage">
                <a :href="getQueryString({ lang: lang.value })" class="language-link">{{lang.text}}</a>
              </li>
            </ul>

          </div>


           <!-- mobile menu launcher -->
          <button class="d-md-none p-nav__mobile ml-auto"  v-on:click="showMobileMenu = true">
            <i class="fas fa-bars  p-nav__mobile__icon"></i>
          </button>

          <!-- mobile menu -->
          <screen-overlay
            :show-overlay="showMobileMenu"
            v-on:closeOverlay="closeMobileMenu"
            class="d-flex flex-column">
            <!-- mobile menu -->
            <template >
              <!-- primary nav links -->
              <ul class="p-nav__list--primary d-inline-flex flex-column ml-12 align-items-start" style="margin-top: 100px;">
                <li v-for="link in settings.links" class="d-inline-block" :class="$route.path == link.url ? 'p-nav__item--active' : ''"><a :href="link.url + getQueryString($route.query)">{{link.display_text}}</a></li>
              </ul>

              <!-- secondary list: tx assessments & language(s) -->
              <ul class="p-nav__list--secondary d-inline-flex flex-column ml-12 mt-100">
                <li class="d-inline-block">
                  <a :href="settings.assessment_link.href" style="text-transform: uppercase;" target="_blank">{{settings.assessment_link.text}}</a>
                </li>
                <!-- language(s) -->
                <li v-if="showLanguages" v-for="lang in otherLanguage" class="d-inline-block">
                  <a :href="getQueryString({ lang: lang.value })" class="language-link">{{lang.text}}</a>
                </li>
              </ul>
            </template>
             </screen-overlay>
        </div>
      </div>
    </div>
  </nav>
  </div>
</template>
<script type="text/javascript">
  import getQueryStringLibrary from 'tembo-js/getQueryString';
  import ScreenOverlay from './overlays/ScreenOverlay.vue';

  export default {
    components: { ScreenOverlay },

    props: ['settings', 'layout'],
    data: function data() {
      return {
        showMobileMenu: false
      };
    },
    methods: {
      getQueryString: getQueryStringLibrary,
      closeMobileMenu() {
        this.showMobileMenu = false;
      },
    },
    computed: {
      otherLanguage: function otherLanguage() {
        var nextLang = [];
        var i = 0;
        var optionCt = this.settings.language_options.options.length;

        const currentLang = this.$store.state.language;
        while (i < optionCt) {
          if (this.settings.language_options.options[i].value !== currentLang) {
            nextLang.push(this.settings.language_options.options[i]);
          }
          i += 1;
        }
        return nextLang;
      },
      showLanguages: function showLanguages() {
        if (this.settings.disable_languages === true) {
          return false;
        }
        return true;
      }
    },
  };
</script>
