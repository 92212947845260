<template>
  <div v-if="layout.modal">
    <div v-for="(component, idx) in layout.modal"
      :show="showModal"
      :row="modalData"
      :is="component.tag"
      :key="idx"
      :settings="component.data"
      :layout="component.layout"
      :class="component.tag">
    </div>
    <span v-on:click="openModal()">language</span>
  </div>
</template>
<script type="text/javascript">
  var ModalMixin = require('../mixins/ModalMixin');

  module.exports = {
    mixins: [ModalMixin],
    props: [
      'settings',
      'labels',
      'layout'
    ]
  };
</script>