import handleDataMap from './handleDataMap'; // eslint-disable-line import/no-cycle

/*  eslint-disable no-else-return */
function passesFilters(item, filters, settings) {
  for (let i = 0, l = filters.length; i < l; i++) {
    const filter = filters[i];

    let filterValue = filter.value;
    if (typeof filterValue !== 'undefined' && filterValue !== null) {
      if (typeof filterValue === 'object' && filterValue.type && filterValue.type === 'root'
        && settings) {
        filterValue = handleDataMap(filterValue, settings);
      }
    }
    if (typeof filterValue !== 'undefined' && filterValue !== null) {
      filterValue = filterValue.toString();
    }

    let itemValue = handleDataMap(filter.field, item);
    if (typeof itemValue !== 'undefined' && itemValue !== null) {
      itemValue = itemValue.toString();
    }


    const numericLogic = ['GTE', 'LTE', 'GT', 'LT'];
    let numberError = false;
    if (filter.logic) {
      if (numericLogic.indexOf(filter.logic) > -1) {
        // if filter logic requires numbers, force values to be numbers
        // if either is not a number, filter does not pass
        if (Number.isNaN(Number(itemValue))) {
          // eslint-disable-next-line no-console, max-len
          console.error(`Item value ${itemValue} cannot be converted to a number and cannot be compared using ${filter.logic} logic`);
          numberError = true;
        }
        if (Number.isNaN(Number(filterValue))) {
          // eslint-disable-next-line no-console, max-len
          console.error(`Filter value ${itemValue} cannot be converted to a number and cannot be compared using ${filter.logic} logic`);
          numberError = true;
        }
        if (numberError) return false;

        itemValue = Number(itemValue);
        filterValue = Number(filterValue);
      }
      if (filter.logic.toUpperCase() === 'GTE') {
        if (itemValue < filterValue) return false;
      } else if (filter.logic.toUpperCase() === 'LTE') {
        if (itemValue > filterValue) return false;
      } else if (filter.logic.toUpperCase() === 'GT') {
        if (itemValue <= filterValue) return false;
      } else if (filter.logic.toUpperCase() === 'LT') {
        if (itemValue >= filterValue) return false;
      } else if (filter.logic.toUpperCase() === 'NOT_EMPTY') {
        if (itemValue === null || itemValue === '' || typeof itemValue === 'undefined') {
          return false;
        }
      } else if (filter.logic.toUpperCase() === 'MISSING') {
        if (Object.prototype.hasOwnProperty.call(item, filter.field)) return false;
        if (itemValue !== null) return false;
      } else if (filter.logic.toUpperCase() === 'NE') {
        if (itemValue === filterValue) return false;
      } else {
        // eslint-disable-next-line no-console
        console.error(`tembo-js/passesFilters does not currently handle the logic ${filter.logic}`);
      }
    } else if (itemValue !== filterValue) {
      return false;
    }
  }
  return true;
}
/*  eslint-enable no-else-return */

export default passesFilters;
