<template>
  <label class="c-input" data-has-options="true"
    :class="optionsVisible ? 'c-input--open' : ''"
    :title="placeholderValue">
    <div class="c-inputtt flex-grow-1" style="overflow: hidden;">
      <div class="c-input__value"
        @click.stop="handleInputClick">
        <span class="mr-2 d-flex align-items-center"><i v-if="iconClass" :class="iconClass" class="mr-2"></i>
        <span
          :class="localSelection ? '' : 'c-input__value--placeholder'">{{localText || placeholderValue}}</span></span><i class="fas fa-caret-down c-input__icon--dropdown c-input__icon"></i>
      </div>
      <div class="c-input__options"
        v-if="optionsVisible">
        <div tabindex="0" class="c-input__option"
          v-for="option in orderedOptions"
          :disabled="option.disabled"
          @click.stop="handleOptionClick(option)">{{option.text}}</div>
      </div>
    </div>
    <select class="sort-select sr-only"
      v-model="localSelection"
      @focus="hasFocus = false"
      @blur="hasFocus = false">
      <option class="special" disabled
        v-if="placeholderValue"
        :value="null">{{placeholderValue}}</option>
      <option
        v-for="option in orderedOptions"
        :value="option.value"
        :disabled="option.disabled">{{option.text}}</option>
    </select>
  </label>
</template>

<script type="text/javascript">
  import $ from 'jquery';
  const utils = require('../store/utils');
  module.exports = {
    props: ['options', 'selected', 'placeholderValue', 'useTitle', 'iconClass'],
    data() {
      return {
        optionsVisible: false,
        hasFocus: false
      };
    },
    methods: {
      closeDropdown: function closeDropdown() {
        // set value to close the dropdown
        this.optionsVisible = false;
      },
      openDropdown: function openDropdown() {
        var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        // var ua = navigator.userAgent.toLowerCase();
        // var isAndroid = ua.indexOf('android') > -1;
        if (!iOS) {
          // set value to open the dropdown
          this.optionsVisible = true;
        }
      },
      handleInputClick: function handleInputClick() {
        // check if dropdown is open
        if (this.optionsVisible) {
          // dropdown is open, call function to close it
          this.closeDropdown();
        } else {
          // dropdown is closed, call function to open it
          this.openDropdown();
        }
      },
      handleOptionClick: function handleOptionClick(option) {
        this.localSelection = option.value;
        this.closeDropdown();
      },
      handleBodyClick: function handleBodyClick(ev) {
        const target = ev.target;
        const parents = $(target).parents(); // all parents of click target
        const el = $(this.$el)[0]; // the jquery obj of this component
        let isCompChild = false;
        parents.each((idx, parent) => {
          if (el === parent) {
            // click target is a child of this component
            isCompChild = true;
          }
        });
        // only close the dropdown if we've clicked on something
        // this isn't a part of this component
        if (!isCompChild) {
          this.closeDropdown();
        }
      },
    },
    computed: {
      localSelection: {
        get: function localSelection() {
          return this.selected;
        },
        set: function localSelection(newval) {
          this.$emit('update:selected', newval);
        }
      },
      localText() {
        if (!this.localSelection) return null;
        return this.options.find(opt => opt.value === this.localSelection).text;
      },
      orderedOptions() {
        return utils.sort(this.options);
      }
    },
    watch: {
      optionsVisible() {
        const self = this;
        // check if dropdown is open
        if (self.optionsVisible) {
          document.body.addEventListener('click', self.handleBodyClick);
        } else {
          document.body.removeEventListener('click', self.handleBodyClick);
        }
      }
    }
  };
</script>