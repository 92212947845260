import { render, staticRenderFns } from "./LetterGradeCheckbox.vue?vue&type=template&id=28ec8280&scoped=true&"
import script from "./LetterGradeCheckbox.vue?vue&type=script&lang=js&"
export * from "./LetterGradeCheckbox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28ec8280",
  null
  
)

export default component.exports