<!--
home_page:
  welcome_image_src: globals.globals.0.find.product.home.image_mobile
  welcome_image_alt_text: globals.globals.0.intro_screen.image_alternate_text
  title_text: globals.globals.0.intro_screen.title_text
  sub_title_text: globals.globals.0.find.product.home.sub_title_text
  long_text: globals.globals.0.find.product.home.long_text
  search_placeholder_text: globals.globals.0.find.product.home.search_placeholder_text
  search_no_results: globals.globals.0.find.product.home.search_no_results
  find_routes: globals.globals.0.find.product.home.find_routes
  footnote: globals.globals.0.find.product.home.footnote
 -->
<template>
  <div class="intro-modal-container d-flex flex-column">
    <component
      v-if="layout && layout.site_nav"
      :is="layout.site_nav.tag"
      :settings="layout.site_nav.data"
      :layout="layout.site_nav.layout"
      class="position-relative flex-shrink-0"
      style="z-index: 1; "
    ></component>

    <div class="container  pt-5 flex-grow-1 flex-shrink-0 d-flex flex-column">
      <div class="row">
        <div class="col-12 col-lg-10 offset-lg-1 d-flex flex-column justify-content-around">

          <!-- WELCOME -->
          <div class="" style="position:relative">
            <div class="row">
             <a :href="settings.find_routes[0].href.indexOf('http') > -1 ? settings.find_routes[0].href : settings.find_routes[0].href + getQueryString($route.query)">
              <img class="texas-img d-none d-lg-block" :src="settings.welcome_image_src" :alt="settings.welcome_image_alt_text || ''">
              </a>
              <div class="col-12 col-lg-7 intro-text">
                <div class="ml-lg-4">
                  <rich-text
                  :text="settings.intro_html"
                  :skip-sanitize="true"
                  ></rich-text>
                </div>
              </div>
            </div>

            <!-- SEARCH -->
            <!-- <div class="mt-4"> -->
            <div class="fields-container py-md-40 px-md-30 px-12 py-16" style="background-color: #70417f; border-radius:16px;">
              <div class="row">
                <div class="col-lg-7 col-12">
                  <!-- entity search -->
                  <div style="position: relative; font:bold " v-if="settings.search" class="tea-input mb-2">
                    <typeahead-dropdown-link
                      v-if="settings.search"
                      :placeholder="settings.search.placeholder"
                      :options="settings.search.options"
                      :link-template="settings.search.link_template"
                      :no-results="settings.search.no_results"
                      class="w-100"
                    ></typeahead-dropdown-link>
                  </div>
                  <span class="or-text my-2 font-weight-bold d-block text-center">{{settings.or_text}}</span>
                  <!-- address entry -->
                  <div style="position: relative; " v-if="settings.search" class="tea-input ">
                    <component
                      v-if="layout.address_entry"
                      :is="layout.address_entry.tag"
                      :settings="layout.address_entry.data"
                      class="w-100"
                    ></component>
                  </div>
                </div>
              </div>
            </div>
            <p class="mt-3 mt-md-0 c-modal--cta__p text-center text-md-left d-md-none">{{settings.long_text}}</p>
            <!-- </div> -->
            <div class="row mt-20 mt-20 flex-md-row-reverse">
              <div class="col-md-7 col-12">
                <!-- FIND & OTHER ROUTES/LINKS -->
                <template v-if="settings.find_routes">
                  <div v-for="row in Math.ceil(settings.find_routes.length / 2)" class="row">
                  <!--<div v-for="(route, index) in settings.find_routes" class="col-5">-->
                  <template v-for="col in 2">
                    <div v-if="row * 2 + col - 3 < settings.find_routes.length" class="d-flex flex-row align-items-center col-sm-12 my-3 my-lg-4" :class="col === 1 ? 'offset-md-1 col-md-4' : 'offset-md-1 col-md-4'">
                      <img :srcset="settings.find_routes[row * 2 + col - 3].img_srcset"
                        :src="settings.find_routes[row * 2 + col - 3].img_src"
                        :alt="settings.find_routes[row * 2 + col - 3].img_alt_text" class="homepage--link-image">

                      <div class="text-center text-md-left ml-3">
                        <p class="p-hero-links__title">{{settings.find_routes[row * 2 + col - 3].prompt_text}}</p>
                        <a class="p-hero-links__link" :target="settings.find_routes[row * 2 + col - 3].href.indexOf('http') > -1 ? '_blank' : '' " :href="settings.find_routes[row * 2 + col - 3].href.indexOf('http') > -1 ? settings.find_routes[row * 2 + col - 3].href : settings.find_routes[row * 2 + col - 3].href + getQueryString($route.query)" v-html="lineBreakJoin(settings.find_routes[row * 2 + col - 3].link_text)"></a>
                      </div>
                    </div>
                  </template>
                  </div>
                </template>
              </div>
              <div class="col-md-5 col-12">
                <rich-text
                  :text="settings.video_embed"
                  :skipSanitize="true"
                ></rich-text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <component
      v-if="layout && layout.footer"
      :is="layout.footer.tag"
      :settings="layout.footer.data"
      class="flex-shrink-0 pt-20"
    ></component> <!--removed mt-gutter from class -->
  </div>
</template>
<script type="text/javascript">

  import TypeaheadDropdownLink from '../TypeaheadDropdownLink.vue';
  import getQueryStringLibrary from 'tembo-js/getQueryString';
  import utils from '../../store/utils';

  module.exports = {
    props: ['settings', 'layout'],
    components: { TypeaheadDropdownLink },
    mounted() {
      let loc;
      if (!this.settings.page_title) {
        loc = window.location;
        document.title = loc.hostname + loc.pathname + loc.search;
      } else {
        document.title = this.settings.page_title;
      }
      this.$store.dispatch('updateAddress', []);
    },
    methods: {
      getQueryString: getQueryStringLibrary,
      lineBreakJoin: utils.lineBreakJoin,
    },
    computed: {
      linkedFootnoteText() {
        return utils.linkedTextHtml({
          originalText: this.settings.footnote.text,
          replacements: [{
            externalLink: true,
            text: this.settings.footnote.linked_text,
            href: this.settings.footnote.url
          }],
        });
      },
      home() {
        return this.$store.state.home;
      },
    },
    watch: {
      home: {
        handler(curr) {
          if (curr.user) {
            const query = this.$route.query;
            query.filter_distance = 'default';
            query['sort_computed-distance_from_home'] = 1;
            query.temp_params = 'filter_distance,sort_computed-distance_from_home';
            this.$router.push({ name: 'find', query: query, params: { findView: 'schools' } });
          }
        },
        deep: true,
      }
    }
  };
</script>
<style type="text/css" scoped>
  img{
    max-width: 100%;
  }
</style>
