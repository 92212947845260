import { render, staticRenderFns } from "./ResultItemRow.vue?vue&type=template&id=779cfb10&scoped=true&"
import script from "./ResultItemRow.vue?vue&type=script&lang=js&"
export * from "./ResultItemRow.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "779cfb10",
  null
  
)

export default component.exports