<template>
  <div v-if="renderableLayout" class="d-flex flex-column h-100">
    <!-- MODALS -->
    <!-- each modal requires tag, data/settings and show
      compares, favorites & address are the only items required
      to sync with other components
      other properties are required by some, but not all
     -->
      <div v-if="$store.state.showModal && $store.state.modalTag && $store.state.modalData"
        :is="$store.state.modalTag"
        :show="$store.state.showModal"
        @update:show="$store.dispatch('updateShowModal', false)"
        :settings="$store.state.modalData"

        :layout="$store.state.modalLayout"
      ></div>

    <!-- HEADERS -->
      <header class="header p-header--static">
        <a href="#main-content" class="sr-only">Skip to main content</a>
        <div v-for="(component, idx) in renderableLayout.header"
          :is="component.tag"
          :settings="component.data"
          :layout="component.layout"
          :class="component.tag"
          :headerType="'containerWidth'"
          :key="idx"
          >
        </div>
      </header>
      <div class="map-wrapper">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <div
                :is="renderableLayout.map.tag"
                :settings="renderableLayout.map.data"
                :layout="renderableLayout.map.layout"
                :class="renderableLayout.map.tag"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="container flex-grow-1" id="main-content">
        <div class="row align-items-center">
          <div class="col-sm-6 col-md-5">
            <div
              :is="renderableLayout.tab_filter.tag"
              :settings="renderableLayout.tab_filter.data"
              :layout="renderableLayout.tab_filter.layout"
              :class="renderableLayout.tab_filter.tag"
            ></div>
          </div>
          <div class="col-sm-6 col-md-7">
            <div
              :is="renderableLayout.name_search.tag"
              :settings="renderableLayout.name_search.data"
              :layout="renderableLayout.name_search.layout"
              :class="renderableLayout.name_search.tag"
            ></div>
          </div>
        </div>
        <div class="row my-3">
          <div class="col-sm-12">
            <div
              :is="renderableLayout.results_status.tag"
              :settings="renderableLayout.results_status.data"
              :layout="renderableLayout.results_status.layout"
              :class="renderableLayout.results_status.tag"
            ></div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div
              v-for="(component, idx) in renderableLayout.table"
              :is="component.tag"
              :settings="component.data"
              :layout="component.layout"
              :class="component.tag"
              :key="idx"
            ></div>
          </div>
        </div>
      </div>

    <!-- FOOTER -->
    <div class="container-fluid p-footer" id="footer">
      <div
        :is="renderableLayout.footer.tag"
        :settings="renderableLayout.footer.data"
      ></div>
    </div>
  </div>
</template>
<script type="text/javascript">
  var AppMixinBase = require('../mixins/AppMixinBase');
  var AppMixinFilters = require('../mixins/AppMixinFilters');

  module.exports = {
    mixins: [AppMixinBase, AppMixinFilters],
    computed: {
      breakpoint() {
        return this.$store.state.breakpoint;
      }
    }
  };
</script>
<style type="text/css" scoped>
</style>
