var Vue = require('vue');
var VueResource = require('vue-resource');
import $ from 'jquery';
import handleDataMap from 'tembo-js/handleDataMap';
import getQueryString from 'tembo-js/getQueryString';
import stripHTML from 'tembo-js/stripHTML';
import sendEvent from 'tembo-js/sendEvent';
import displayListAsRange from 'tembo-js/displayListAsRange';

Vue.use(VueResource);

function getAuthMethod(ctx, method) {
  var authMethod;
  try {
    authMethod = ctx.getters.defaults.auth_prefix + method;
  } catch (e) {
    console.error('Finder.yaml must include valid defaults.auth_prefix'); // eslint-disable-line
  }
  return authMethod;
}

function lineBreakJoin(list) {
  if (Array.isArray(list)) {
    const strippedList = list.map(stripHTML);
    return strippedList.join('<br/>');
  }
  return stripHTML(list);
}

function linkedTextHtml({ originalText, replacements }) {
  if (!originalText) {
    console.error('no originalText provided'); // eslint-disable-line no-console
    return '';
  }
  if (!Array.isArray(replacements)) {
    console.error('replacements must be a list.'); // eslint-disable-line no-console
    return originalText;
  }

  let strippedText = stripHTML(originalText);

  // replace multiple pieces of text with <a> links
  for (let i = 0, l = replacements.length; i < l; i ++) {
    const link = replacements[i];
    const linked = stripHTML(link.text || '');
    const ref = link.href || '';

    if (link.hasOwnProperty('externalLink') && link.externalLink) {
      strippedText = strippedText.replace(linked, `<a href="${ref}" target="_blank">${linked}</a>`);
    } else {
      strippedText = strippedText.replace(linked, `<a href="${ref}">${linked}</a>`);
    }
  }
  return strippedText;
}

function buildUrl(ctx, route) {
  //
  // return appropriate url for accessing the api
  //
  var queryString;

  let lang;
  if (ctx.rootState.language) {
    lang = ctx.rootState.language;
  } else if (ctx.state.language) {
    lang = ctx.state.language;
  }
  let database;
  if (ctx.rootState.database) {
    database = ctx.rootState.database;
  } else if (ctx.state.database) {
    database = ctx.state.database;
  }
  const queryObj = { db: database, lang: lang };

  // Check if the base route already defines a query string
  // If so, just append the database and/or lang properties to it
  if (route.indexOf('?') === -1) {
    queryString = '?';
  } else {
    queryString = '&';
  }

  // This is a loop in AirBnB's Javascript world. Shoot me now.
  Object.keys(queryObj).forEach((key) => {
    if (queryObj[key]) {
      queryString += key + '=' + queryObj[key] + '&';
    }
  });

  // Beacuse we construct the string with an extra character (either '?' or '&')
  // we need to remove it before adding the query string to the route
  queryString = queryString.slice(0, -1);
  const url = `${route}${queryString}`;
  return url;
}

function getFromAPI(ctx, route) {
  const url = buildUrl(ctx, route.url);
  let method = 'get';
  let body;
  const headers = {
    Accept: 'application/vnd.tembo.api+json'
  };
  if (route.hasOwnProperty('merge_datapoints')) {
    headers['Content-Type'] = 'application/json';
    method = 'post';
    body = JSON.stringify(route.merge_datapoints);
  } else if (route.hasOwnProperty('merges')) {
    headers['Content-Type'] = 'application/json';
    method = 'post';
    body = JSON.stringify({ merges: route.merges });
  }

  return Vue.http[method](url, body, { headers: headers })
  .then(null, (err) => {
    console.error('failed to load', route, err); // eslint-disable-line no-console
  });
}

function generalizeLoc(loc) {
  var updatedLoc = {};
  try {
    updatedLoc.lat = loc.lat || loc.latitude;
    updatedLoc.lng = loc.lng || loc.long || loc.longitude;
  } catch (e) {
    // console.error('Location error', e); // eslint-disable-line no-console
  }
  return updatedLoc;
}


function extend(obj1, obj2) {
  var keys;
  var i;
  var l;
  var result;
  if (obj2) {
    keys = Object.keys(obj2);
  }
  if (!obj1 && !obj2) return null;
  if (!obj1 && obj2) return obj2;
  if (obj1 && !obj2) return obj1;
  result = JSON.parse(JSON.stringify(obj1));
  for (i = 0, l = keys.length; i < l; i ++) {
    if (result.hasOwnProperty(keys[i])) {
      console.warn('key', keys[i], 'already exists on target.', obj1, ' overwriting with', obj2[keys[i]]); // eslint-disable-line
    }
    result[keys[i]] = obj2[keys[i]];
  }
  return result;
}

function getSortValue(a, b) {
  if (a < b) return -1;
  if (b < a) return 1;
  return 0;
}

function sort(list) {
  return list.slice().sort((a, b) => {
    if (a.hasOwnProperty('order') && b.hasOwnProperty('order')) {
      return getSortValue(parseFloat(a.order, 10), parseFloat(b.order, 10));
    }
    if (a.hasOwnProperty('text') && b.hasOwnProperty('text')) {
      return getSortValue(a.text, b.text, 10);
    }
    if (a.hasOwnProperty('display_text') && b.hasOwnProperty('display_text')) {
      return getSortValue(a.display_text, b.display_text, 10);
    }
    if (a.hasOwnProperty('value') && b.hasOwnProperty('value')) {
      return getSortValue(a.value, b.value, 10);
    }
    return getSortValue(a, b);
  });
}

function differenceOfTwoArraySets(arr1, arr2) {
  let difference = arr1.filter(item => arr2.indexOf(item) === -1);
  difference = difference.concat(arr2.filter(item => arr1.indexOf(item) === -1));
  return difference;
}

function convertBooleanStrings(val) {
  var convertedVal;
  if (val === 'TRUE' || val === 'true') {
    convertedVal = true;
  } else if (val === 'FALSE' || val === 'false') {
    convertedVal = false;
  } else {
    convertedVal = val;
  }
  return convertedVal;
}

function convertArrayStrings(str) {
  // eslint-disable-next-line
  var findArray = /\[(.*?)\]/g;
  var match = findArray.exec(str);
  if (match) {
    if (match[1].length === 0) return [];
    return match[1].split(',');
  }
  return str;
}

function flattenToValues(obj) {
  const keys = Object.keys(obj);
  let result = [];
  for (let i = 0, l = keys.length; i < l; i++) {
    const key = keys[i];
    const value = obj[key];
    if (typeof value === 'undefined' || value === null || typeof value === 'string'
      || typeof value === 'boolean' || typeof value === 'number') {
      result.push(value);
    } else {
      result = result.concat(flattenToValues(value));
    }
  }
  return result;
}

function convertToString(datum) {
  if (typeof datum === 'string') {
    return datum;
  }
  if (typeof datum === 'undefined' || datum === null) {
    return '';
  }
  if (typeof datum === 'boolean' || typeof datum === 'number') {
    return datum.toString();
  }
  if (typeof datum === 'function') {
    console.error('will not convert function to a string'); // eslint-disable-line no-console
    return '';
  }
  // object
  const arrayOfValues = flattenToValues(datum);
  return arrayOfValues.map(v => convertToString(v));
}

function makeUrlReplacements(template, entity) {
  let url = template;
  const replaceMap = {
    id: '[entity_id]',
    parent_id: '[parent_id]',
    entity_type: '[entity_type]',
  };
  const replaceKeys = Object.keys(replaceMap);
  for (let i = 0, l = replaceKeys.length; i < l; i ++) {
    const key = replaceKeys[i];
    const replaceThis = replaceMap[key];
    url = url.replace(replaceThis, entity[key]);
  }
  return url;
}

function getTemplatedEntityUrl(entity, ctx, templates) {
  let url = templates;
  try {
    url = makeUrlReplacements(url, entity);
  } catch (e) {
    url = handleDataMap(templates, entity);
    url = makeUrlReplacements(url, entity);
  }
  if (!url) return url;// NOTE: please be more specific about what you are looking for here
  return url + getQueryString(ctx.$route.query, ['compare', 'compareids']);
}

function getEntityUrl(entity, ctx, templates) {
  if (templates) return getTemplatedEntityUrl(entity, ctx, templates);
  return '/schools/' + entity.id + getQueryString(ctx.$route.query, ['compare', 'compareids']);
}


function setupLinkClickEvents(vueCtx, category, linkIdentifier) {
  const gaLinkElements = $(vueCtx.$el).find(`${linkIdentifier || '.ga-link'}`);
  gaLinkElements.click((ev) => {
    const target = $(ev.target);
    const label = target.attr('href');
    let action;
    if (label.indexOf('http') > -1) {
      action = 'external_link_selected';
    } else if (label.indexOf('mailto') > -1) {
      action = 'mail_link_selected';
    } else {
      action = 'link_selected';
    }
    sendEvent({
      category: category,
      action: action,
      label: label,
    });
  });
}


module.exports = {
  // probably pretty specific
  getFromAPI,
  buildUrl,
  generalizeLoc,
  convertBooleanStrings,
  convertArrayStrings,
  getAuthMethod,
  differenceOfTwoArraySets,
  displayListAsRange,
  flattenToValues,
  convertToString,
  getTemplatedEntityUrl,
  getEntityUrl,
  lineBreakJoin,
  linkedTextHtml,
  // used once, so far
  setupLinkClickEvents,
  // re-used often
  extend,
  sort,
};
