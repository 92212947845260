<template>

    <!-- More Filters -->
    <div class="more-filters-list flex-column o-two-column-layout__filter-list" v-bind:class="expanded ? 'more-filters-list--is-open' : 'more-filters-list--is-closed'" :aria-hidden="expanded ? 'false' : 'true'" :tabindex="expanded ? '0' : '-1'">
      <div class="filter-group-container flex-grow-1 o-two-column-layout__filter-list__container">

        <!-- Mobile info -->
        <div class="visible-xs visible-sm d-md-none">
          <div class="filter-group-label ">
            <div class="d-flex mx-0 py-3 px-4">
              <div class="flex-grow-1">{{settings.address_grade_header_text}}</div>
              <span class="a" v-on:click="$store.dispatch('updateShowModal', { modal: 'address_grade' })" v-on:keyup.enter="$store.dispatch('updateShowModal', { modal: 'address_grade' })">{{settings.change_text}}</span>
            </div>
          </div>

          <!-- Filter info -->
          <div class="static-filter py-3 px-4">
            <p class="filter-info" v-if="home">{{home.address.formatted_address}}</p>
            <p class="filter-info" v-if="!settings.hide_grade_mobile">{{currentGradeSelection}}</p>
          </div>
        </div>

        <!-- Filters -->
        <div v-if="filteredFilters.length">
          <div class="filter-group-label py-3 px-4">
            <template v-if="!(settings.hide_grade_in_labels)">
              {{currentGradeSelection}}
            </template>
            {{settings.filters_text}}
          </div>
          <div v-for="(filter, idx) in filteredFilters"
            :is="filter.tag"
            :key="idx"
            :settings="filter.data"
            :layout="filter.layout"
            :super-filters="superFilters"
            :home="home"
          ></div>
          <!-- Special filter-related controls, as needed -->
          <template v-if="layout && layout.section_controls && layout.section_controls.filters">
            <div
              v-for="(item, idx) in layout.section_controls.filters"
              :is="item.tag"
              :key="idx"
              :settings="item.data"
              class="col-6 col-xs-6 col-sm-auto py-3 px-4"
            ></div>
          </template>
        </div>

        <!-- Preferences -->
        <div v-if="filteredPreferences.length">
          <div class="filter-group-label py-3 px-4">
            <template v-if="!(settings.hide_grade_in_labels)">
              {{currentGradeSelection}}
            </template>
            {{settings.preferences_text}}</div>
          <div v-for="(preference, idx) in filteredPreferences"
            :is="preference.tag"
            :key="idx"
            :settings="preference.data"
            :layout="preference.layout"
            :super-filters="superFilters"
            :home="home"
          ></div>
          <!-- Special preference-related controls, as needed -->
          <template v-if="layout && layout.section_controls && layout.section_controls.preferences">
            <div
              v-for="(item, idx) in layout.section_controls.preferences"
              :is="item.tag"
              :key="idx"
              :settings="item.data"
              class="col-6 col-xs-6 col-sm-auto py-3 px-4"
            ></div>
          </template>
        </div>
      </div>
      <div
        :is="layout.filter_controls.tag"
        :settings="layout.filter_controls.data"
        :layout="layout.filter_controls.layout"
        class="mt-auto o-two-column-layout__filter-list__controls"
      ></div>
    </div>
</template>
<script type="text/javascript">
  var ReactiveFilterMixin = require('../mixins/ReactiveFilterMixin');

  module.exports = {
    mixins: [ReactiveFilterMixin],
    props: ['settings', 'layout'],
    computed: {
      home: function home() {
        return this.$store.state.home;
      },
      expanded: function expanded() {
        return this.$store.state.moreFiltersExpanded;
      },
      gradeFilter: function gradeFilter() {
        return this.$store.getters.filterSet.grade_levels;
      },
      orderedPreferences: function orderedPreferences() {
        //
        // use the order field mapped from data.
        // if unavailable, order will depend on order of layout list
        //
        if (!this.layout.preferences) return [];
        return this.layout.preferences.sort((a, b) => a.data.order - b.data.order);
      },
      filteredPreferences: function filteredPreferences() {
        if (!this.orderedPreferences) return [];
        if (!this.superFilterSelections) return this.orderedPreferences;
        return this.filterToRenderable(this.orderedPreferences);
      },
      orderedFilters: function orderedFilters() {
        //
        // use the order field mapped from data.
        // if unavailable, order will depend on order of layout list
        //
        if (!this.layout.filters) return [];
        return this.layout.filters.sort((a, b) => a.data.order - b.data.order);
      },
      filteredFilters: function filteredFilters() {
        if (!this.orderedFilters) return [];
        if (!this.superFilterSelections) return this.orderedFilters;
        return this.filterToRenderable(this.orderedFilters);
      },
      currentGradeSelection: function currentGradeSelection() {
        var gradeFilter = this.gradeFilter;
        var value;
        var i;
        var l;
        for (i = 0, l = gradeFilter.value.length; i < l; i ++) {
          value = gradeFilter.value[i];
          if (value.required) return value.text;
        }
        return null;
      }
    }
  };
</script>
