<template>
  <!-- WRAPPER FOR FILTER GROUP -->
  <div :is="layout.wrapper.tag ? layout.wrapper.tag : 'static-filter-group'" :settings="settings" class="combined-filter" :selection-tally="selectionTally">

    <!-- FILTER TITLE -->
    <span slot="filter-title">{{settings.group_title_text}}</span>
    <div slot="filter-tally">{{selectionTally}} {{settings.tally_text}}</div>

    <!-- FILTER TALLY SLOT -->
    <div slot="filter-tally">{{selectionTally}} {{settings.tally_text}}</div>

    <!-- FILTER CONTENT SLOT -->
    <div slot="filter-content" class="w-100">
      <a class="filter-description" href='#' v-if="settings.metadata.descriptions_modal" v-on:click="handleShowDescriptions">{{settings.options_description_text}}</a>

      <div class="filter-content d-flex flex-column flex-lg-row" v-if="layout">

        <div v-for="(filter, idx) in filteredFilters"
          :is="filter.tag"
          :key="idx"
          :settings="filter.data"
          :layout="filter.layout"
          :super-filters="superFilters"
          class="flex-grow-1"
        ></div>

      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
  var FilterGroupMixin = require('../../mixins/FilterGroupMixin');
  var ReactiveFilterMixin = require('../../mixins/ReactiveFilterMixin');
  import sendEvent from 'tembo-js/sendEvent';

  module.exports = {
    mixins: [FilterGroupMixin, ReactiveFilterMixin],
    data: function data() {
      return {
        noresults: false,
        descriptions: false,
        ct: 0
      };
    },
    computed: {
      orderedFilters: function orderedFilters() {
        //
        // use the order field mapped from data.
        // if unavailable, order will depend on order of layout list
        //
        return this.layout.filters.sort((a, b) => a.data.order - b.data.order);
      },
      filteredFilters: function filteredFilters() {
        if (!this.orderedFilters) return [];
        if (!this.superFilterSelections) return this.orderedFilters;
        return this.filterToRenderable(this.orderedFilters);
      }
    },
    methods: {
      handleFilterClick: function handleFilterClick(value) {
        // for handling filters NOT in sub-groups
        var action;
        if (value.required) action = 'select';
        else action = 'deselct';
        sendEvent({
          category: 'filter',
          action: action + '_' + this.settings.metadata.label,
          label: value.value
        });
        // if (this.filtered.length === 0) {
        //   this.noresults = true;
        // }
      }
    }
  };
</script>