<template>
  <div tabindex="-1" aria-hidden="true" id="map-google" style="position: relative;">

    <div id="map-container" class="" >
      <slot><span class="animation--placeholder d-flex fa-6x fa-stack flex-column h-100 justify-content-center mx-auto">
            <i class="fal fa-spinner-third fa-spin fa-stack-2x"></i>
            <i class="fas fa-map-marked-alt fa-stack-1x"></i>
          </span></slot>
    </div>
    <div class="map-legend__container" v-if="settings.legend">

          <div class="map-legend container">
            <p class="map-legend__heading mt-2" v-if="settings.legend.title">{{settings.legend.title}}</p>
            <p class="map-legend__description mt-2" v-if="settings.legend.description">{{settings.legend.description}}</p>
            <div class="mb-0 mt-2 row">
              <div v-for="(item, index) in settings.legend.values" class="map-legend__item mt-2 col-6 col-sm-4 col-lg-6">
                <div class="row no-gutters">
                  <div class="col-3">
                    <i class="fas map-legend__item__marker" :class="item.class" :data-label="item.marker_label"></i>
                  </div>
                  <div class="col-9">
                    <span class="map-legend__item__label ml-1" v-if="item.legend_label">{{item.legend_label}}</span>
                  </div>
                </div>
              </div>
            </div>
      </div>
    </div>

    <div
      v-if="layout && layout.school_item"
      :is="layout.school_item.tag"
      :id="'map-result-item'"
      :settings="layout.school_item.data"
      :entity="mobileSchoolItem"
      @update:entity="val => { mobileSchoolItem = val }"
      :idx="mobileSchoolIndex"
      :x="true"
    ></div>

    <div v-on:mouseenter="clearTooltipTimeout" v-on:mouseleave="hideTooltip">
      <div
        v-if="tooltipVisible && (tooltipTag || defaultTooltipTag) && tooltipEntity"
        :is="tooltipTag || defaultTooltipTag"
        class="map-custom-tooltip d-none d-md-block"
        :class="tooltipClass"
        :style="{top: tooltipLocation.y + 'px', left: tooltipLocation.x + 'px'}"
        :settings="tooltipSettings || defaultTooltipSettings"
        :entity="tooltipEntity"
        :card-type="'map'"
      ></div>
    </div>
  </div>
</template>
<script>
  const utils = require('../store/utils');
  const PaginationMixin = require('../mixins/PaginationMixin');
  import MarkerClusterer from '@google/markerclustererplus';
  import staticData from '../store/data';
  import getDataByProperty from 'tembo-js/getDataByProperty';
  import getNthBearing from 'tembo-js/getNthBearing';
  import arraysMatch from 'tembo-js/arraysMatch';
  import passesFilters from 'tembo-js/passesFilters';
  import sendEvent from 'tembo-js/sendEvent';
  import trim from 'lodash/trim';
  import $ from 'jquery';

  let MarkerWithLabel;

  module.exports = {
    props: ['settings', 'layout', 'mobileMapItem'],
    mixins: [PaginationMixin],
    data() {
      let defaultTooltipTag = null;
      let defaultTooltipSettings = null;
      if (this.layout && this.layout.tooltip) {
        if (this.layout.tooltip.tag) {
          defaultTooltipTag = this.layout.tooltip.tag;
        }
        if (this.layout.tooltip.data) {
          defaultTooltipSettings = this.layout.tooltip.data;
        }
      }
      return {
        tooltipLocation: { x: 0, y: 0 },
        tooltipEntity: null,
        tooltipVisible: false,
        tooltipTimeout: null,
        tooltipMarker: null,
        tooltipMarkerSpec: null,
        tooltipClass: '',
        defaultTooltipTag: defaultTooltipTag,
        tooltipTag: null,
        defaultTooltipSettings: defaultTooltipSettings,
        tooltipSettings: null,
        map: null,
        // separated into entity & center markers
        // allows clusterer to only group entity markers
        markers: {
          entity: [],
          center: [],
        },
        mapHasIdled: false,
        clusterer: null,
      };
    },
    mounted() {
      this.loadMaps();
    },
    computed: {
      filtered: function filtered() {
        return this.$store.state.filtered;
      },
      home: function home() {
        return this.$store.state.home;
      },
      entitiesLoaded: function entitiesLoaded() {
        return this.$store.state.entitiesLoaded;
      },
      mapsApiLoaded: function mapsApiLoaded() {
        return this.$store.state.mapsApiLoaded;
      },
      allStrictFilterCount() {
        return this.$store.getters.allStrictFilterCount;
      },
    },
    methods: {
      loadMaps() {
        try {
          if (google) { // eslint-disable-line no-undef
            this.initializeMap();
          }
        } catch (e) {
          if (window.initializeMap) {
            window.initializeMap();
            return;
          }
          const script = document.createElement('script');
          script.setAttribute('type', 'text/javascript');
          script.src = 'https://maps.googleapis.com/maps/api/js?key=' + staticData.config.defaults.env_vars.google_api_key + '&libraries=places&callback=initializeMap';
          document.getElementsByTagName('body')[0].appendChild(script);
          window.initializeMap = this.initializeMap;
        }
      },
      initializeMap() {
        this.$store.dispatch('updateMapsApiLoaded', true);
        if (!this.mapsDrawn) {
          this.drawMap();
        } else {
          this.clearAllMarkersOfType('center');
          this.clearAllMarkersOfType('entity');
          this.drawMap();
        }
      },
      drawMap() {
        try {
          if (!google) { // eslint-disable-line no-undef
            // eslint-disable-next-line no-console
            console.error('this scripts needed to run the map have failed to load');
            return null;
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('this scripts needed to run the map have failed to load');
          return null;
        }

        //
        // prevent map from calling idle events
        //
        this.mapHasIdled = false;

        //
        // get center for the map - home or default
        //
        let center;
        if (this.home) {
          center = this.home.address.geometry.location;
        } else {
          center = this.settings.center_on;
        }

        //
        // initialize map
        //
        const map = new google.maps.Map(document.querySelector('#map-container'), { // eslint-disable-line no-undef, max-len
          center: center,
          zoom: this.settings.metadata.zoom || 13,
          fullscreenControl: false,
          streetViewControl: false,
        });

        //
        // set component globals for eas of access
        //
        this.map = map;
        this.zoom = map.getZoom();

        //
        // custom event handlers for idle & zoom events
        //
        google.maps.event.addListener(this.map, 'idle', this.idleHandler); // eslint-disable-line no-undef, max-len

        // This zoom handler was previously used to adjust markers
        // of multiple entities with the same location,
        // so the user would be able to see multiple markers, even if mostly overlapped.
        // Not currently used. Leaving here for future reference.
        // google.maps.event.addListener(this.map, 'zoom_changed', this.zoomHandler);

        this.mapsDrawn = true;
        return true;
      },
      idleHandler() {
        // when google map has idled for the first time, alert component
        const self = this;
        self.mapHasIdled = true;
      },
      // zoomHandler() {
        // currently not used
        // when zoom changes, check if markers were previously adjusted
        // to be 3px apart. if they were, re-draw them
        // TODO:
        // zoom is the only place where map markers are adjusted
        // to be at least 3px apart. this should probably change
      //   const self = this;
      //   const prevZoom = this.zoom;
      //   const currZoom = this.map.getZoom();
      //
      //   if (prevZoom - currZoom > 0) {
      //     self.adjustMarkers();
      //   } else if (prevZoom === currZoom < 0 && self.budged) {
      //     self.resetPositions();
      //     self.adjustMarkers();
      //   }
      //   self.zoom = currZoom;
      // },
      boundsContainMarker(bounds, marker) {
        let contained = true;
        const markerPosition = marker.getPosition();
        if (!bounds.contains(markerPosition)) {
          contained = false;
        }
        return contained;
      },
      boundsContainAllMarkers(bounds, markers) {
        let allContained = true;
        const l = markers.length;
        let i = 0;
        while (allContained && i < l) {
          const marker = markers[i];
          allContained = this.boundsContainMarker(bounds, marker);
          i += 1;
        }
        return allContained;
      },
      chooseMapCenter() {
        const map = this.map;
        let center;
        if (this.settings.metadata.re_center === 'home') {
          if (this.home) {
            center = this.home.address.geometry.location;
          } else {
            center = this.settings.center_on;
          }
        } else if (this.settings.metadata.re_center === 'geo') {
          center = map.getCenter();
        } else {
          center = map.getCenter();
        }
        return center;
      },
      fitMapToMarkers() {
        const markers = this.markers.entity.concat(this.markers.center);
        const map = this.map;
        const filterCount = this.allStrictFilterCount;

        let center;
        if (filterCount) {
          if (this.home.user) {
            center = this.chooseMapCenter();
          }
        } else {
          center = this.chooseMapCenter();
        }


        if (!markers.length) {
          // if there are no markers, skip all
          // calculations related to zooming, etc
          // use the default center (according to configs)
          if (!center) center = this.chooseMapCenter();
          map.setCenter(center);
          // and the default zoom
          const defaultZoom = this.settings.metadata.zoom;
          if (defaultZoom) map.setZoom(defaultZoom);
          return;
        }

        let bounds;
        if (center) {
          bounds = new google.maps.LatLngBounds(center, center); // eslint-disable-line no-undef
        } else {
          bounds = new google.maps.LatLngBounds(); // eslint-disable-line no-undef
        }

        //
        // add each marker to bounds
        //
        for (let i = 0, l = markers.length; i < l; i ++) {
          // skip center marker, because its position is unreliable until rendered
          if (markers[i].labelClass !== 'center-marker') {
            const markerPosition = markers[i].getPosition();
            bounds.extend(markerPosition);
          }
        }

        if (center) {
          bounds.extend(center);
        }

        map.fitBounds(bounds);

        let zoom = map.getZoom();
        if (center) {
          map.setCenter(center);
        }

        // zoom in, stepwise to
        // show the map at the largest zoom allowed, while still
        // showing all markers
        let mapBounds = map.getBounds();
        let contained = this.boundsContainAllMarkers(mapBounds, markers);
        while (contained && zoom <= this.settings.metadata.max_zoom) {
          zoom += 1;
          map.setZoom(zoom);
          mapBounds = map.getBounds();
          contained = this.boundsContainAllMarkers(mapBounds, markers);
          if (!contained) {
            zoom -= 1;
            map.setZoom(zoom);
          }
        }

        // zoom back out, as needed
        // especially necessary in areas that cover much more distance e-w than n-s
        while (!contained) {
          // are all markers contained now?
          mapBounds = map.getBounds();
          contained = this.boundsContainAllMarkers(mapBounds, markers);
          if (!contained) {
            zoom -= 1;
            map.setZoom(zoom);
          }
        }
        map.setZoom(zoom);

        if (map.getZoom() > this.settings.metadata.max_zoom) {
          map.setZoom(this.settings.metadata.max_zoom);
        }

        const self = this;
        if (this.settings.use_clusters && this.clusterer) {
          const repaintOnIdle = google.maps.event.addListener(this.map, 'idle', () => { // eslint-disable-line no-undef, max-len
            self.clusterer.repaint();
            google.maps.event.removeListener(repaintOnIdle); // eslint-disable-line no-undef
          });
        }
      },
      drawInitialMarkers() {
        this.clearAllMarkersOfType('entity');
        if (this.entitiesLoaded) {
          this.addAllFiltered();
        }
        this.clearAllMarkersOfType('center');
        this.addCenterMarker();
        this.fitMapToMarkers();
      },
      updateMarkers() {
        if (this.mapHasIdled && this.mapsDrawn && this.mapsApiLoaded && this.filtered) {
          this.clearAllMarkersOfType('entity');
          this.addAllFiltered();
          this.fitMapToMarkers();
        }
      },
      addAllFiltered() {
        let entities;
        if (!staticData.usePagination || this.settings.use_clusters) {
          entities = this.filtered;
        } else {
          entities = this.pageXEntities || [];
        }
        // entities = this.adjustMarkers(entities);
        if (this.settings.adjust_markers) {
          this.adjustMarkers(entities);
        }

        entities.forEach(function eachFiltered(entity) {
          this.addOneMarker(entity);
        }, this);


        if (this.settings.use_clusters) {
          // each size handles the next largest power of 10
          // assuming for now that we won't need to handle clusters of more than 9999 differently
          // the map will still render them, they will be styled using cluster--xl
          const clusterOptions = {
            zoomOnClick: false,
            styles: [
              { className: 'cluster--sm', height: 25, width: 25 }, // clusters with 1-9 markers
              { className: 'cluster--md', height: 25, width: 25 }, // clusters with 10-99 markers
              { className: 'cluster--lg', height: 25, width: 25 }, // clusters with 100-999 markers
              { className: 'cluster--xl', height: 25, width: 25 }  // clusters with 1000+ markers
            ],
            maxZoom: this.settings.use_clusters.max_zoom || 17,
          };
          clusterOptions.minimumClusterSize = 5;
          if (this.settings.cluster_minimum && typeof this.settings.cluster_minimum === 'number') {
            clusterOptions.minimumClusterSize = this.settings.cluster_minimum;
          }
          this.clusterer = new MarkerClusterer(this.map, this.markers.entity, clusterOptions);

          const map = this.map;
          //
          // overwrite the default cluster click handler
          // https://github.com/googlemaps/v3-utility-library/issues/437#issuecomment-624088546
          //
          /* eslint-disable no-undef, no-underscore-dangle, no-param-reassign */
          google.maps.event.addListener(this.clusterer, 'click', e => {
            /**
             * This event is fired when a cluster marker is clicked.
             * @name MarkerClusterer#click
             * @param {Cluster} e The cluster that was clicked.
             * @event
             */
            google.maps.event.trigger(e, 'click', e.markerClusterer_);
            google.maps.event.trigger(e, 'clusterclick', e.markerClusterer_); // deprecated name
            // The default click handler follows. Disable it by setting
            // the zoomOnClick property to false.
            // Zoom into the cluster.
            const maxZoom = e.markerClusterer_.getMaxZoom();
            const originalZoom = map.getZoom();
            const theBounds = e.getBounds();
            map.fitBounds(theBounds);
            // Fixes #437
            setTimeout(() => {
              map.fitBounds(theBounds); // this fixes an issue on iOS #170
              let currentZoom = map.getZoom();
              currentZoom = Math.max(currentZoom, originalZoom + 1);
              // Don't zoom beyond the max zoom level if maxZoom specified
              // or ensure we zoom at least one level over original zoom level.
              if (maxZoom !== null && currentZoom > maxZoom) {
                map.setZoom(maxZoom + 1);
              } else {
                map.setZoom(currentZoom);
              }
            }, 100);
            // }
            // Prevent event propagation to the map:
            e.cancelBubble = true;
            if (e.stopPropagation) {
              e.stopPropagation();
            }
          });
          /* eslint-enable */
        }
      },
      addCenterMarker() {
        if (this.home && (this.settings.metadata.plot_default || this.home.user)) {
          this.addOneMarker({ location: this.home.address.geometry.location, type: 'center' });
        }
      },
      addOneMarker(data) {
        const markerSpec = this.settings.markers.find((spec) => {
          let found = false;
          if (Array.isArray(spec.filter)) {
            found = passesFilters(data, spec.filter);
          } else {
            found = passesFilters(data, [spec.filter]);
          }
          return found;
        });
        let location = getDataByProperty(this.settings.metadata.match_data_path, data);
        location = utils.generalizeLoc(location);
        if (location.lat && location.lng) {
          const marker = this.makeMarker({
            location,
            data,
            markerSpec,
          });
          marker.setMap(this.map);
        }
      },
      makeMarker({ location, data, markerSpec }) {
        const map = this.map;
        const self = this;

        if (!MarkerWithLabel) {
          // we import MarkerWithLabel here, because requiring it must be loaded AFTER
          // the google maps library, which is loaded dynamically on component mount

          // eslint-disable-next-line global-require
          MarkerWithLabel = require('../../plugins/markerwithlabel');
        }

        // build the svg that will be the icon google recognizes.
        // this is the area on the map that can be activated by mouseover events
        const svgPath = `M 0,0 ${markerSpec.svg.width},0 ${markerSpec.svg.width},${markerSpec.svg.height} 0,${markerSpec.svg.height} z`; // eslint-disable-line max-len

        const svgAnchor = this.getAnchorPoint(markerSpec.svg);

        let dataLabel = '';
        if ('data_label_attribute' in markerSpec) {
          dataLabel = getDataByProperty(markerSpec.data_label_attribute, data);
        }
        let dataClass = '';
        if ('data_class_attribute' in markerSpec) {
          dataClass = getDataByProperty(markerSpec.data_class_attribute, data);
        }

        // used to determine margins for positioning the icon properly
        // values: dot or pin
        const labelAnchorClass = `map-marker--${markerSpec.label.anchor_type}`;

        const marker = new MarkerWithLabel({
          icon: {
            path: svgPath,
            scale: 1,
            anchor: svgAnchor,
            strokeWeight: 0,
            fillOpacity: 0,
          },
          labelAnchor: { x: 0, y: 0 },
          labelSize: markerSpec.label,
          labelData: dataLabel,
          position: location,
          map: map,
          data: data,
          labelContent: '',
          labelClass: ['map-marker', markerSpec.label.class, dataClass, labelAnchorClass].join(' '),
          markerSpec: markerSpec,
        });


        google.maps.event.addListener(marker, 'mouseover', () => { // eslint-disable-line no-undef, max-len
          // remove hovered labelClass from all markers
          self.removeClassAllEntityMarkers(markerSpec.label.hover_class);
          // update marker class of current marker
          const markerLabelClass = trim(marker.labelClass) + ` ${markerSpec.label.hover_class}`;
          marker.set('labelClass', markerLabelClass);
          if (!!!markerSpec.no_tooltip) {
            // show tooltip
            self.showTooltip(marker, data, markerSpec);
          }
          const findView = this.$route.params.findView;
          const category = findView ? `${findView}_map` : 'map';
          sendEvent({
            category: category,
            action: 'marker_select',
            label: marker.data.id,
          });
        });
        google.maps.event.addListener(marker, 'mouseout', () => { // eslint-disable-line no-undef, max-len
          const markerLabelClass = trim(marker.labelClass.replace(markerSpec.label.hover_class, ''));
          marker.set('labelClass', markerLabelClass);
          self.hideTooltip();
        });


        // for mobile map items
        google.maps.event.addListener(marker, 'click', () => { // eslint-disable-line no-undef
          if (marker.data.type !== 'center') {
            // show tooltip, in case it's tablet-sized
            self.showTooltip(marker, data, markerSpec);
            self.$emit('update:mobileMapItem', marker.data);
            // remove hovered labelClass from all markers
            self.removeClassAllEntityMarkers(markerSpec.label.hover_class);
            // update marker class of current marker
            const markerLabelClass = trim(marker.labelClass) + ` ${markerSpec.label.hover_class}`;
            marker.set('labelClass', markerLabelClass);
            const findView = this.$route.params.findView;
            const category = findView ? `${findView}_map` : 'filter';
            sendEvent({
              category: category,
              action: 'marker_select',
              label: marker.data.id,
            });

            // remove click-away listeners leftover from last time
            $('body').off('click.marker-clickoff');


            if (this.tooltipVisible) {
              // set up click-away listener to hide tooltip when user clicks anything else
              // primarily for tablets - desktop size, but without hover events
              $('body').on('click.marker-clickoff', () => {
                if ($('.map-custom-tooltip').is(':visible') && this.tooltipVisible) {
                  this.hideTooltip();
                }
              });
            }
          }
        });


        this.markers[markerSpec.type].push(marker);
        return marker;
      },
      removeClassAllEntityMarkers(className) {
        this.markers.entity.forEach((marker) => {
          const markerLabelClass = trim(marker.labelClass.replace(className, ''));
          marker.set('labelClass', markerLabelClass);
        });
      },
      getAnchorPoint(spec) {
        const anchor = { x: 0, y: 0 };
        if (spec.anchor_type) {
          const anchorType = spec.anchor_type;
          if (anchorType === 'dot') {
            anchor.x = spec.width / 2;
            anchor.y = spec.height / 2;
          } else if (anchorType === 'pin') {
            anchor.x = spec.width / 2;
            anchor.y = spec.height;
          }
        } else if (spec.anchor) {
          // anchor must have x and y properties
          anchor.x = spec.anchor.x || 0;
          anchor.y = spec.anchor.y || 0;
        }
        const anchorPoint = new google.maps.Point(anchor.x, anchor.y); // eslint-disable-line no-undef, max-len
        return anchorPoint;
      },
      getMarkerRelativePosition(marker, map) {
        const markerScreenPosition = this.getPixelPosition(marker, map);

        const topLeftMapPosition = this.getMapTopLeftPosition(map);

        const position = {
          x: markerScreenPosition.x - topLeftMapPosition.x - marker.labelAnchor.x,
          y: markerScreenPosition.y - topLeftMapPosition.y - marker.labelAnchor.y,
        };
        return position;
      },
      getMapTopLeftPosition(map) {
        var zoom = map.getZoom();
        var scale = 1 << zoom;
        var topLeft = new google.maps.LatLng( // eslint-disable-line no-undef, no-new, new-cap
              map.getBounds().getNorthEast().lat(),
              map.getBounds().getSouthWest().lng()
          );
        var projection = map.getProjection();
        var topLeftWorldCoordinate = projection.fromLatLngToPoint(topLeft);
        var topLeftPixelCoordinate = new google.maps.Point( // eslint-disable-line no-undef, no-new, new-cap, max-len
                topLeftWorldCoordinate.x * scale,
                topLeftWorldCoordinate.y * scale);

        return topLeftPixelCoordinate;
      },
      getPixelPosition(marker, map) {
        var zoom = map.getZoom();
        var scale = 1 << zoom;
        var loc = { lat: marker.getPosition().lat(), lng: marker.getPosition().lng() };
        var worldCoordinate = this.project(loc);
        var pixelCoordinate;
        // eslint-disable-next-line no-undef, no-new, new-cap
        pixelCoordinate = new google.maps.Point(
            Math.floor(worldCoordinate.x * scale),
            Math.floor(worldCoordinate.y * scale));
        return pixelCoordinate;
      },
      project(latLng) {
        var TILE_SIZE = 256;
        var siny = Math.sin(latLng.lat * Math.PI / 180);

        // Truncating to 0.9999 effectively limits latitude to 89.189. This is
        // about a third of a tile past the edge of the world tile.
        // siny = Math.min(Math.max(siny, -1), 1);
        siny = Math.min(Math.max(siny, -0.9999), 0.9999);

        // eslint-disable-next-line no-undef
        const point = new google.maps.Point(
            TILE_SIZE * (0.5 + latLng.lng / 360),
            TILE_SIZE * (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI)));
        return point;
      },
      adjustMarkers(entities) {
        //
        // adjusts markers by 10 feet from original location
        //

        // entity prop for location info
        const locationProp = this.settings.metadata.match_data_path;

        // build sets of entities base on location
        const locationSets = entities.reduce((acc, curr) => {
          const loc = getDataByProperty(locationProp, curr);
          const locationId = `${loc.lat},${loc.lng}`;
          if (acc.hasOwnProperty(locationId)) {
            acc[locationId].push(curr);
          } else {
            acc[locationId] = [curr]; // eslint-disable-line no-param-reassign
          }
          return acc;
        }, {});

        function feetToGPS(feet) {
          const earthRadiusMiles = 3961;
          const earthCircumfrenceMiles = 2 * Math.PI * earthRadiusMiles;
          const earthCircumfrenceFeet = earthCircumfrenceMiles * 5280;
          return 360 * feet / earthCircumfrenceFeet;
        }

        const locationIds = Object.keys(locationSets);
        for (let i = 0, l = locationIds.length; i < l; i ++) {
          const id = locationIds[i];
          const set = locationSets[id];
          if (set.length > 1) {
            // adjust the entity locations of locations
            // with multiple entities
            for (let j = 0, k = set.length; j < k; j ++) {
              const entity = set[j];
              const currentLocation = getDataByProperty(locationProp, entity);
              const theta = getNthBearing(j, k);
              const adjustByFt = this.settings.adjust_markers.ft || 15;
              const adjustBy = feetToGPS(adjustByFt);
              const newLocation = {
                lng: currentLocation.lng + theta[0] * adjustBy,
                lat: currentLocation.lat + theta[1] * adjustBy,
              };
              entity.location.lat = newLocation.lat;
              entity.location.lng = newLocation.lng;
            }
          }
        }
      },
      showTooltip(marker, data, markerSpec) {
        this.clearTooltipTimeout(this.tooltipTimeout);
        this.tooltipVisible = true;
        this.tooltipEntity = data;
        this.tooltipLocation = this.getMarkerRelativePosition(marker, this.map);
        this.tooltipMarker = marker;
        this.tooltipMarkerSpec = markerSpec;
        if (markerSpec.label.anchor_type) {
          this.tooltipClass = `map-custom-tooltip--${markerSpec.label.anchor_type}`;
        }
      },
      clearTooltipTimeout() {
        clearTimeout(this.tooltipTimeout);
      },
      hideTooltip() {
        const markerSpec = this.tooltipMarkerSpec;
        if (!markerSpec) return;
        const marker = this.tooltipMarker;
        this.tooltipTimeout = setTimeout(() => {
          const replaceThis = new RegExp(markerSpec.label.hover_class, 'g');
          marker.set('labelClass', marker.labelClass.replace(replaceThis, ''));
          this.tooltipVisible = false;
          // clean-up body click event
          $('body').off('click.marker-clickoff');
          // this.tooltipEntity = null;
          // this.tooltipMarkerSpec = null;
          // this.tooltipMarker = null;
        }, 300);
      },
      clearAllMarkersOfType(type) {
        const remainingMarkers = [];
        for (let i = 0, l = this.markers[type].length; i < l; i ++) {
          const marker = this.markers[type][i];
          if (marker.markerSpec.type === type) {
            // remove from map
            marker.setMap(null);
            // remove event listeners
            google.maps.event.clearInstanceListeners(marker); // eslint-disable-line no-undef
          } else {
            remainingMarkers.push(marker);
          }
        }
        if (type === 'entity' && this.settings.use_clusters && this.clusterer) {
          // remove all markers from the clusterer
          this.clusterer.clearMarkers();
        }
        this.markers[type] = remainingMarkers;
      }
    },
    watch: {
      mapHasIdled(curr, prev) {
        if (prev === false && curr === true && (this.markers.entity.length === 0 || this.markers.center.length === 0)) { // eslint-disable-line max-len
          this.drawInitialMarkers();
          this.fitMapToMarkers();
        }
      },
      filtered: {
        handler(curr, prev) {
          //
          // when the list of entities change,
          // update markers
          // and reset the mobileSchoolItem
          //
          const prevList = prev.map(e => e.id);
          const currList = curr.map(e => e.id);
          const updateItems = !arraysMatch(prevList, currList);
          if (updateItems && this.settings.use_clusters) {
            // this.mobileZoomSet = false;
            this.$nextTick(this.updateMarkers());
          }
        },
        deep: true
      },
      pageXEntities: {
        handler: function pageXEntities(curr, prev) {
          //
          // when the list of entities change,
          // update markers
          // and reset the mobileSchoolItem
          //
          const prevList = prev.map(e => e.id);
          const currList = curr.map(e => e.id);
          const updateItems = !arraysMatch(prevList, currList);
          if (updateItems && !this.settings.use_clusters) {
            // this.mobileZoomSet = false;
            this.$nextTick(this.updateMarkers());
          }
        },
        deep: true
      },
      home() {
        this.clearAllMarkersOfType('center');
        this.addCenterMarker();
        this.fitMapToMarkers();
      },
      mobileMapItem(curr, prev) {
        if (prev && !curr) {
          const markers = this.markers.entity;
          for (let i = 0, l = markers.length; i < l; i ++) {
            const marker = markers[i];
            google.maps.event.trigger(marker, 'mouseout'); // eslint-disable-line no-undef
          }
        }
      },
    }
  };
</script>
<style type="text/css">
  .map-custom-tooltip {
    position: absolute;
    background-color: white;
    transform: translate(-50%, -100%) translateY(-12px);
    margin-left: 0;
    margin-bottom: 1rem !important;
    margin-right: 0;
    z-index: 1000;
    overflow: visible !important;
    border: 0;
  }
  .map-custom-tooltip:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -1rem;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-top: 1rem solid white;
    z-index: 100;
  }
  .map-custom-tooltip .p-entity-card__info {
    min-height: auto;
  }
</style>
