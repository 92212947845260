<template>
  <div v-if="renderableLayout" class="d-flex flex-column h-100">
    <!-- MODALS -->
    <!-- each modal requires tag, data/settings and show
      compares, favorites & address are the only items required
      to sync with other components
      other properties are required by some, but not all
     -->
      <div v-if="$store.state.showModal && $store.state.modalTag && $store.state.modalData"
        :is="$store.state.modalTag"
        :show="$store.state.showModal"
        @update:show="$store.dispatch('updateShowModal', false)"
        :settings="$store.state.modalData"

        :layout="$store.state.modalLayout"
      ></div>
<div class="p-header p-header--static">
  <a href="#main-content" class="sr-only">Skip to main content</a>
  <div class="container">
   <div v-for="(component, idx) in renderableLayout.header"
          :is="component.tag"
          :settings="component.data"
          :layout="component.layout"
          :class="component.tag"
          :key="idx">
        </div>
      </div>
</div>


    <!-- HEADERS -->
      <!-- <header class="header p-header p-header--static"> -->
        <!-- <a href="#main-content" class="sr-only">Skip to main content</a> -->
        <!-- <div class="container"><div class="row"> -->
      <!--   <div v-for="component in renderableLayout.header"
          :is="component.tag"
          :settings="component.data"
          :layout="component.layout"
          :class="component.tag">
        </div> -->
        <!-- </div></div> -->
        <!-- <div class="nav-highlight"></div> -->
      <!-- </header> -->

      <!-- CONTENT -->
      <div class="container flex-grow-1" id="main-content">

        <!-- Main filter (entity type) -->
        <div class="row">
          <div class="col-sm-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
            <div
              v-if="$store.state.breakpoint !== 'smartphone'"
              :is="renderableLayout.main_prompt.tag"
              :settings="renderableLayout.main_prompt.data"
              :layout="renderableLayout.main_prompt.layout"
              :class="renderableLayout.main_prompt.tag"
              class="text-center my-3"
            >{{renderableLayout.main_prompt.data}}</div>
            <div
              :is="renderableLayout.main_filter.tag"
              :settings="renderableLayout.main_filter.data"
              :layout="renderableLayout.main_filter.layout"
              :class="renderableLayout.main_filter.tag"
              class="my-2 my-md-4"
              @update:selected="handleSuperFilterChange"
            ></div>
          </div>
        </div>
        <div class="row my-4">
          <div class="col-sm-12 col-md-6">
            <div
              v-for="(filter, idx) in filteredFilters"
              :is="filter.tag"
              :settings="filter.data"
              :layout="filter.layout"
              :class="filter.tag"
              :key="idx"
            ></div>
          </div>
        </div>
        <div class="row row-eq-height--above-md search-input-row align-items-end">
          <div class="col-sm-12 col-lg">
            <div
              v-if="filteredNameSearch && breakpoint !== 'smartphone'"
              :is="filteredNameSearch.tag"
              :settings="filteredNameSearch.data"
              :layout="filteredNameSearch.layout"
              :class="filteredNameSearch.tag"
            ></div>
          </div>
          <div class="col-sm-12 col-lg-auto">
            <div class="row result-information mt-3 mt-lg-0">
              <div class="col-sm-auto col-12">
                <div
                  :is="renderableLayout.results_status.tag"
                  :settings="renderableLayout.results_status.data"
                  :layout="renderableLayout.results_status.layout"
                  :class="renderableLayout.results_status.tag"
                ></div>
              </div>
              <div class="col-sm col-12">
                <div
                  :is="renderableLayout.pagination_toggle.tag"
                  :settings="renderableLayout.pagination_toggle.data"
                  :layout="renderableLayout.pagination_toggle.layout"
                  :class="renderableLayout.pagination_toggle.tag"
                  class="pull-right"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-result-table mt-3">
          <div class="col-sm-12">
            <div
              v-for="(component, idx) in renderableLayout.table"
              :is="component.tag"
              :settings="component.data"
              :layout="component.layout"
              :class="component.tag"
              :key="idx"
            ></div>
          </div>
        </div>
      </div>

      <div
              :is="renderableLayout.footer.tag"
              :settings="renderableLayout.footer.data"
              class="mt-2"
            ></div>

    <!-- FOOTER -->
<!--     <div class="container-fluid p-footer mt-3" id="footer">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div
              :is="renderableLayout.footer.tag"
              :settings="renderableLayout.footer.data"
            ></div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script type="text/javascript">
  var AppMixinBase = require('../mixins/AppMixinBase');
  var AppMixinFilters = require('../mixins/AppMixinFilters');
  var ReactiveFilterMixin = require('../mixins/ReactiveFilterMixin');

  module.exports = {
    mixins: [AppMixinBase, AppMixinFilters, ReactiveFilterMixin],
    data: function data() {
      return {
        filteredNameSearch: null
      };
    },
    computed: {
      breakpoint: function breakpoint() {
        return this.$store.state.breakpoint;
      },
      filteredFilters: function filteredFilters() {
        if (!this.renderableLayout.filters) return [];
        if (!this.superFilterSelections) return this.renderableLayout.filters;
        return this.filterToRenderable(this.renderableLayout.filters);
      },
      selectedEntityType: function selectedEntityType() {
        const filters = this.filters;
        if (!filters || !filters.entity_type) return null;
        const selected = filters.entity_type.value.filter(val => val.required);
        if (selected.length) return selected[0].value;
        return selected;
      },
    },
    methods: {
      handleSuperFilterChange: function handleSuperFilterChange() {
        this.$store.dispatch('resetAllFilters', false);
      },
    },
    watch: {
      selectedEntityType: function selectedEntityType(curr, prev) {
        if (curr !== prev) {
          const nameSearches = this.renderableLayout.name_search;
          if (!nameSearches) return;
          if (!curr) return;
          const nameSearch = nameSearches.filter(search => search.data.entity_type[0] === curr)[0];
          this.filteredNameSearch = nameSearch;
        }
      }
    }
  };
</script>
<style type="text/css" scoped>
.row + .row{
  /* margin-top:  40px; */
}
.row-result-table.row-result-table{
  /* margin-top:  5px; */
}

</style>
