<template>
  <div>
    <div v-for="(filter, idx) in filteredFilters"
      :is="filter.tag"
      :key="idx"
      :settings="filter.data"
      :layout="filter.layout"
      :super-filters="superFilters"
    ></div>
  </div>
</template>
<script type="text/javascript">
  var ReactiveFilterMixin = require('../mixins/ReactiveFilterMixin');

  module.exports = {
    mixins: [ReactiveFilterMixin],
    props: ['settings', 'layout'],
    computed: {
      orderedFilters: function orderedFilters() {
        //
        // use the order field mapped from data.
        // if unavailable, order will depend on order of layout list
        //
        if (!this.layout.filters) return [];
        return this.layout.filters.sort((a, b) => a.data.order - b.data.order);
      },
      filteredFilters: function filteredFilters() {
        if (!this.orderedFilters) return [];
        if (!this.superFilterSelections) return this.orderedFilters;
        return this.filterToRenderable(this.orderedFilters);
      },
    },
  };
</script>
